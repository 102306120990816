import React from 'react';
import './GamesCards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='top-games-container'>
        <div className='games-title-container'>
        <h1>Top Games</h1>
        <hr/>
        </div>
          <div className='cards__container'>
              <ul className='cards__items'>
                <CardItem
                  src='images/fifa-23.png'
                  label='FC25'
                  path='/games'
                />
                <CardItem
                  src='https://assets.2k.com/1a6ngf98576c/5lvjCUPKs5vj2aVyzDobJh/ae2575e0e37c5e84d2e4b49978974384/N24-WEB-HOMEPAGE-RETAIL_CAROUSEL-KOBE_BRYANT_EDITION-MODULE_2-425x535-R2.jpg'
                  label='NBA 2K'
                  path='/games'
                />
                <CardItem
                  src='https://cdn.cloudflare.steamstatic.com/steam/apps/1778820/header.jpg?t=1692957440  a'
                  label='TEKKEN'
                  path='/games'
                />
              </ul>
              <ul className='cards__items'>
                <CardItem
                  src='https://www.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blog/hero/mwiii/MWIII-REVEAL-FULL-TOUT.jpg'
                  label='CALL OF DUTY'
                  path='/games'
                />
                <CardItem
                  src='images/street-fighter.png'
                  label='STREET FIGHTER'
                  path='/games'
                />
                <CardItem
                  src='images/pubg.png'
                  label='PUBG'
                  path='/games'
                />
              </ul>
          </div>
      </div>          
        </div>

  );
}

export default Cards;
