import React, { useState } from 'react';
import './ProductDemoSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import '../pages/VideoPopup.css';
import YouTube from 'react-youtube';

function ProductDemoSection() {
    const [button, setButton] = useState(true);
    const isLoggedIn = localStorage.getItem("isAuthenticated");

  
    const [openState, setOpenState] = useState(false);

    const toggleModal = () => {
      setOpenState(!openState);
    }; 

  return (
    <div className='product-demo-container'>
      <div className='product-demo-items'>
        <div onClick={toggleModal}>
          <img className="pubg-video" src='/images/product-demo.png' alt="Product Demo" />
        </div>
        {openState && (
            <div className="video-modal">
              <div className="video-content">
                <span className="close-btn" onClick={toggleModal}>
                  &times;
                </span>
                <div className='youtube-video'>
                <YouTube
                  videoId="T9pxDHe-xKo"
                  opts={{
                    width: '100%',
                    height: '250',
                    playerVars: {
                      controls: 0,
                    },
                }}
              />
              </div>
            </div>
          </div>
        )}
        <h2>
            Providing the right tools for the gaming community
        </h2>
        {isLoggedIn && (
          <Link className='btn-link'  to='/user-dashboard'>
            {button && <Button className='btn' buttonStyle='btn--primary'>DASHBOARD</Button>}
          </Link>
        )}
        {isLoggedIn ? (<div></div>) :
          <Link className='btn-link'  to='/sign-up'>{button && <Button className='btn' buttonStyle='btn--primary'>SIGN UP FOR FREE</Button>}
          </Link>
        }
      </div>
      
    </div>
  )
}

export default ProductDemoSection
