import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from '../../components/HeroSection';
import GamesCards from '../../components/GamesCards';
import HowItWorksSection from '../../components/HowItWorksSection';
import AboutUs from '../../components/AboutUs';
import ProductDemoSection from '../../components/ProductDemoSection';
import Footer from '../../components/Footer';
import HomeNavbar from '../../components/HomeNavbar';

function Home() {

  useEffect(() => {
    document.title = 'Cheda Gaming | Home';
  }, []);

  const siteTitle = 'Earn while hosting or playing online tournaments';
  const siteDescription = 'Built specifically to connect gamers all across Africa. Reimagining how gamers hosts, stream, play and earn from online tournaments.';
  const featuredImage = 'https://wallpaperaccess.com/full/8625450.jpg';

  return (
    <>
      <Helmet>
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:image" content={featuredImage} />
      </Helmet>

      <HomeNavbar />
      <HeroSection />
      <AboutUs />
      <HowItWorksSection />
      <GamesCards />
      <ProductDemoSection />
      <Footer />
    </>
  );
}

export default Home;
