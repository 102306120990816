import React from 'react'
import './AboutUs.css'

function AboutUs() {
  return (
    <div className='about-us-container'>
        <div className='about-us-items-title'> 
            <h2>
              Building an ecosystem that connects gamers across Africa
            </h2>
        </div>
        <div>
          <ul className='about-us-items-list'>
            <li className='items'>
               <img src='/images/play_console.gif' alt='Game controller'/>
               <div className='items-desc'>
                  <h3>Play online tournaments</h3>
                  <p>Play paid tournaments on our platform.</p>
                </div>
            </li>
            <li className='items items-left'>
                <img src='/images/play_tournament.gif' alt='Tournaments Icon'/>
                <div className='items-desc'>
                  <h3>Host Online Tournaments</h3>
                  <p>With access to a wide range of gamers cut across Africa.</p>
                </div>
            </li>
            <li className='items items-left'>
                <img src='/images/earn_tournament.gif' alt='Bag of money'/>
                <div className='items-desc'>
                    <h3>Earn From Online Tournaments</h3>
                    <p>Earn from hosting and playing online tournaments.</p>
                </div>
            </li>
            <li className='items  items-left'>
                <img src='/images/live_streaming.gif' alt='Livestreaming Icon' style={{width: '120px'}}/>
                <div className='items-desc'>
                    <h3>Livestream Online Tournaments</h3>
                    <p>Stream all online tournaments hosted on our platforms.</p>
                </div>
            </li>
          </ul>
        </div>
      
    </div>
  )
}

export default AboutUs
