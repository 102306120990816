import React, { useState } from "react";
import './GamesDetails.css';
import { Link } from "react-router-dom";
import gamesData from '../userdata/games.json';
import './VideoPopup.css';
import YouTube from 'react-youtube';


const   GamesDetails = () => {

  const [openStates, setOpenStates] = useState(new Array(gamesData.games.length).fill(false));
  
  const toggleModal = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  const [startIndex, setStartIndex] = useState(0);

  const getVideoIdFromURL = (url) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
  
    if (match && match[1]) {
      return match[1];
    }
  
    return null;
  };

  const handleNextSet = () => {
    setStartIndex((prevIndex) => (prevIndex + 2) % gamesData.games.length);
  };

  const handlePrevSet = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? gamesData.games.length - 2 : prevIndex - 2
    );
  };

  const displayItems = gamesData.games.slice(startIndex, startIndex + 2);

  return (
      <div className="game-details-section" style={{ maxHeight: "750px" }}>
        {displayItems.map((game, index) => (
          <div key={index} className="game-details-container">
            <img src={game.backgroundImage} alt='' /> 
            <div className="game__details__wrapper">
              <div className="game__text__container">
                <div className="year">
                  <p>2023</p>
                </div>
                <div className="text-wrapper">
                  <h2>{game.name}</h2>
                  <p>{game.description}</p>
                </div>
                <div className="icon-btn-container">
                  <div onClick={() => toggleModal(index)}>
                  <img src="../../images/play-button-circled.png" alt="pubg mobile" />
                  </div>
                  {openStates[index] &&  (
                <div className="video-modal">
                  <div className="video-content">
                    <span className="close-btn" onClick={() => toggleModal(index)}>
                      &times;
                    </span>
                    <YouTube
                      videoId={getVideoIdFromURL(game.videoURL)}
                      opts={{
                        width: '560',
                        height: '315',
                        playerVars: {
                          controls: 0,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

                  <Link to='/tournaments#join-tournaments-section' className="earning-link">
                    Start earning
                  </Link>
                </div>
              </div>
              <div className="game-image">
                <img src={game.gameImage} alt={game.name} />
              </div>
            </div>
          </div>
        ))}
      {/* </div> */}
      <div className="game-list">
        <div className="arrow-bck-right" onClick={handlePrevSet}>
          <i className="fas fa-arrow-left" />
        </div>
        <ul>
          <li>
            <img src="../../images/pubg-li.png" alt="Pubg Mobile" />
          </li>
          <li>
            <img src="https://www.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blog/hero/mwiii/MWIII-REVEAL-FULL-TOUT.jpg" alt="Call Of Duty" />
          </li>
          <li>
            <img src="https://cdn.cloudflare.steamstatic.com/steam/apps/1778820/header.jpg?t=1692957440  a" alt="Tekken" />
          </li>
          <li>
            <img src="../../images/street-fighter-li.png" alt="Street Fighter" />
          </li>
          <li>
            <img src="https://assets.2k.com/1a6ngf98576c/5lvjCUPKs5vj2aVyzDobJh/ae2575e0e37c5e84d2e4b49978974384/N24-WEB-HOMEPAGE-RETAIL_CAROUSEL-KOBE_BRYANT_EDITION-MODULE_2-425x535-R2.jpg" alt="NBA 2K" />
          </li>
          <li>
            <img src="../../images/fifa-li.png" alt="FC25" />
          </li>
        </ul>
        <div className="arrow-bck-left" onClick={handleNextSet}>
          <i className="fas fa-arrow-right"></i>
        </div>
      </div>
    </div>
  );
}

export default GamesDetails;
